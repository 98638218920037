<template>
  <!--  <div class="row mr-3 mt-0">-->
  <div class="w-300px mr-3">
    <v-autocomplete
      :value="selectedWarehouse"
      @input="setWarehouse"
      label="Warehouse"
      :items="$store.getters.getUserWareHouses"
      item-text="text"
      item-value="index"
      dense
      outlined
      clearable
      hide-details
      hide-spin-buttons
    ></v-autocomplete>
  </div>
  <!--  </div>-->
</template>
<!--        v-model="$store.getters.getSelectedWarehouse"-->

<script>
import { SET_WAREHOUSE } from "@/core/services/store/auth.module";

export default {
  name: "WarehouseSelect",
  // data: function () {
  //   return {
  //     warehouse: null,
  //   };
  // },
  // mounted() {
  //   this.warehouse = this.$store.getters.getSelectedWarehouse;
  //   // console.log("this.warehouse", this.warehouse);
  // },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    setWarehouse(value) {
      // console.log(".warehouse", value);
      this.$store.commit(SET_WAREHOUSE, value);
    },
  },
};
</script>
